<template>
  <div class="min-h-screen bg-slate-50">
    <!-- Hero Section -->
    <section class="relative bg-white overflow-hidden">
      <div class="max-w-7xl mx-auto">
        <div class="grid md:grid-cols-2 gap-8 px-4 pt-12 pb-16 sm:px-6 lg:px-8">
          <!-- Left Column - Content -->
          <div class="flex flex-col gap-6 self-start">
            <h1 class="text-4xl sm:text-5xl font-bold text-gray-900 tracking-tight">
              Gestiona tus propiedades de forma <span class="text-indigo-700">sencilla</span>
            </h1>
            <p class="text-xl text-gray-500">
              Simplifica la gestión de tus propiedades con contratos digitales, sin intermediarios y al mejor precio.
            </p>
            
            <ul class="space-y-4">
              <li class="flex items-center gap-2">
                <CheckCircleIcon class="h-5 w-5 text-blue-600" />
                <span class="text-gray-700">Gestión simplificada</span>
              </li>
              <li class="flex items-center gap-2">
                <CheckCircleIcon class="h-5 w-5 text-blue-600" />
                <span class="text-gray-700">Elección directa de solicitantes</span>
              </li>
              <li class="flex items-center gap-2">
                <CheckCircleIcon class="h-5 w-5 text-blue-600" />
                <span class="text-gray-700">Contratos 100% digitales</span>
              </li>
            </ul>

            <div class="flex flex-col sm:flex-row gap-4 mt-4">
              <router-link
                to="/signup"
                class="w-full sm:w-auto inline-flex justify-center items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-500 transition-colors"
              >
                Empezar ahora
              </router-link>
              <button
                @click="scrollToCallSection"
                class="w-full sm:w-auto inline-flex justify-center items-center px-6 py-3 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 transition-colors"
              >
                Te llamamos
              </button>
              <router-link
                to="/login"
                class="w-full sm:w-auto inline-flex justify-center items-center px-6 py-3 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 transition-colors"
              >
                Iniciar sesión
              </router-link>
            </div>
          </div>

          <!-- Right Column - Image -->
          <div class="relative self-start">
            <div class="relative w-full grid grid-cols-2 gap-6">
              <!-- Portada Image - Top Left -->
              <div class="relative z-30 transform hover:-translate-y-1 transition-transform duration-300">
                <img
                  src="@/assets/Portada.png"
                  alt="Dashboard view"
                  class="rounded-lg shadow-xl w-full h-auto"
                />
              </div>
              
              <!-- ContractPreview Image - Top Right -->
              <div class="relative z-20 transform translate-y-6 hover:-translate-y-1 transition-transform duration-300">
                <img
                  src="@/assets/ContractPreview.png"
                  alt="Contract preview"
                  class="rounded-lg shadow-xl w-full h-auto"
                />
              </div>
              
              <!-- Listing Image - Bottom Left -->
              <div class="relative z-10 transform translate-y-8 hover:-translate-y-1 transition-transform duration-300">
                <img
                  src="@/assets/Listing.png"
                  alt="Property listing view"
                  class="rounded-lg shadow-xl w-full h-auto"
                />
              </div>
              
              <!-- Acciones Image - Bottom Right -->
              <div class="relative z-20 transform translate-y-16 hover:-translate-y-1 transition-transform duration-300">
                <img
                  src="@/assets/Acciones.png"
                  alt="Actions view"
                  class="rounded-lg shadow-xl w-full h-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Features Grid -->
    <section class="py-24 bg-gray-50">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center max-w-3xl mx-auto mb-16">
          <h2 class="text-4xl font-bold text-gray-900 mb-6">
            ¿Por qué elegir nuestra plataforma?
          </h2>
          <p class="text-xl text-gray-600">
            Descubre la herramienta que necesitas para gestionar tus propiedades de forma directa
          </p>
        </div>

        <!-- Feature Navigation -->
        <div class="flex justify-center mb-12">
          <div class="inline-flex rounded-lg p-1 bg-gray-100">
            <button
              v-for="tab in featureTabs"
              :key="tab.id"
              @click="activeTab = tab.id"
              :class="[
                'px-4 py-2 text-sm font-medium rounded-md transition-all duration-200',
                activeTab === tab.id
                  ? 'bg-white text-blue-600 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              ]"
            >
              {{ tab.label }}
            </button>
          </div>
        </div>

        <!-- Feature Content -->
        <div class="mt-8">
          <!-- Publicación Content -->
          <div v-show="activeTab === 'publicacion'" class="grid md:grid-cols-2 gap-12">
            <div class="space-y-6 self-start">
              <div class="inline-flex mb-4">
                <span class="px-3 py-1 text-sm font-medium bg-blue-100 text-blue-800 rounded-full">
                  Publicación
                </span>
              </div>
              <h3 class="text-3xl font-bold text-gray-900">Publica tus propiedades en minutos</h3>
              <p class="text-lg text-gray-600">
                Crea anuncios atractivos y profesionales con todas las herramientas que necesitas.
              </p>
              <ul class="space-y-4 mt-8">
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="h-6 w-6 text-blue-600 flex-shrink-0 mt-1" />
                  <div>
                    <h4 class="font-medium text-gray-900">Fotos en alta resolución</h4>
                    <p class="text-gray-600 mt-1">Sube y edita fotos profesionales directamente desde la plataforma</p>
                  </div>
                </li>
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="h-6 w-6 text-blue-600 flex-shrink-0 mt-1" />
                  <div>
                    <h4 class="font-medium text-gray-900">Descripciones optimizadas</h4>
                    <p class="text-gray-600 mt-1">Muestra la disponibilidad y las características de tu propiedad</p>
                  </div>
                </li>
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="h-6 w-6 text-blue-600 flex-shrink-0 mt-1" />
                  <div>
                    <h4 class="font-medium text-gray-900">Gestión de la privacidad</h4>
                    <p class="text-gray-600 mt-1">Evita que la información sensible de tu propiedad sea vista por todos</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="relative self-start">
              <img
                src="@/assets/Portada.png"
                alt="Publicación de propiedades"
                class="rounded-xl shadow-xl w-full"
              />
              <div class="absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"></div>
            </div>
          </div>

          <!-- Contratos Content -->
          <div v-show="activeTab === 'contratos'" class="grid md:grid-cols-2 gap-12">
            <div class="space-y-6 self-start">
              <div class="inline-flex mb-4">
                <span class="px-3 py-1 text-sm font-medium bg-purple-100 text-purple-800 rounded-full">
                  Legal
                </span>
              </div>
              <h3 class="text-3xl font-bold text-gray-900">Contratos digitales seguros</h3>
              <p class="text-lg text-gray-600">
                Genera y firma contratos legales de forma digital con total seguridad jurídica.
              </p>
              <ul class="space-y-4 mt-8">
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="h-6 w-6 text-blue-600 flex-shrink-0 mt-1" />
                  <div>
                    <h4 class="font-medium text-gray-900">Plantillas verificadas</h4>
                    <p class="text-gray-600 mt-1">Documentos legales actualizados y validados por expertos</p>
                  </div>
                </li>
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="h-6 w-6 text-blue-600 flex-shrink-0 mt-1" />
                  <div>
                    <h4 class="font-medium text-gray-900">Firma digital segura</h4>
                    <p class="text-gray-600 mt-1">Proceso de firma con validez legal y verificación de identidad</p>
                  </div>
                </li>
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="h-6 w-6 text-blue-600 flex-shrink-0 mt-1" />
                  <div>
                    <h4 class="font-medium text-gray-900">Almacenamiento certificado</h4>
                    <p class="text-gray-600 mt-1">Custodia digital segura de todos los documentos firmados</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="relative self-start">
              <img
                src="@/assets/ContractPreview.png"
                alt="Contratos digitales"
                class="rounded-xl shadow-xl w-full"
              />
              <div class="absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"></div>
            </div>
          </div>

          <!-- Gestión Content -->
          <div v-show="activeTab === 'gestion'" class="grid md:grid-cols-2 gap-12">
            <div class="space-y-6 self-start">
              <div class="inline-flex mb-4">
                <span class="px-3 py-1 text-sm font-medium bg-green-100 text-green-800 rounded-full">
                  Gestión
                </span>
              </div>
              <h3 class="text-3xl font-bold text-gray-900">Gestión integral de propiedades</h3>
              <p class="text-lg text-gray-600">
                Administra todas tus propiedades desde un único panel de control.
              </p>
              <ul class="space-y-4 mt-8">
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="h-6 w-6 text-blue-600 flex-shrink-0 mt-1" />
                  <div>
                    <h4 class="font-medium text-gray-900">Control de pagos y recordatorios automáticos</h4>
                    <p class="text-gray-600 mt-1">Configuración de pagos y recordatorios automáticos</p>
                  </div>
                </li>
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="h-6 w-6 text-blue-600 flex-shrink-0 mt-1" />
                  <div>
                    <h4 class="font-medium text-gray-900">Seguimiento de documentaciones</h4>
                    <p class="text-gray-600 mt-1">Gestión de documentaciones para la venta o alquiler de tu propiedad</p>
                  </div>
                </li>
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="h-6 w-6 text-blue-600 flex-shrink-0 mt-1" />
                  <div>
                    <h4 class="font-medium text-gray-900">Seguimiento de contratos</h4>
                    <p class="text-gray-600 mt-1">Seguimiento de datos de los solicitantes</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="relative self-start">
              <img
                src="@/assets/Acciones.png"
                alt="Gestión de propiedades"
                class="rounded-xl shadow-xl w-full"
              />
              <div class="absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"></div>
            </div>
          </div>

          <!-- Ofertas Content -->
          <div v-show="activeTab === 'ofertas'" class="grid md:grid-cols-2 gap-12">
            <div class="space-y-6 self-start">
              <div class="inline-flex mb-4">
                <span class="px-3 py-1 text-sm font-medium bg-orange-100 text-orange-800 rounded-full">
                  Ofertas
                </span>
              </div>
              <h3 class="text-3xl font-bold text-gray-900">Sistema de ofertas transparente</h3>
              <p class="text-lg text-gray-600">
                Recibe y gestiona ofertas de forma organizada y profesional.
              </p>
              <ul class="space-y-4 mt-8">
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="h-6 w-6 text-blue-600 flex-shrink-0 mt-1" />
                  <div>
                    <h4 class="font-medium text-gray-900">Comparación de ofertas en tiempo real</h4>
                    <p class="text-gray-600 mt-1">Visualización de ofertas en tiempo real y comparación</p>
                  </div>
                </li>
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="h-6 w-6 text-blue-600 flex-shrink-0 mt-1" />
                  <div>
                    <h4 class="font-medium text-gray-900">Verificación de candidatos automatizada</h4>
                    <p class="text-gray-600 mt-1">Automatización del proceso de verificación de candidatos</p>
                  </div>
                </li>
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="h-6 w-6 text-blue-600 flex-shrink-0 mt-1" />
                  <div>
                    <h4 class="font-medium text-gray-900">Gestión segura dentro de la plataforma</h4>
                    <p class="text-gray-600 mt-1">Rapidez y seguridad en la negociación de ofertas dentro de la plataforma</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="relative self-start">
              <img
                src="@/assets/Listing.png"
                alt="Sistema de ofertas"
                class="rounded-xl shadow-xl w-full"
              />
              <div class="absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Replace the How It Works section -->
    <section class="py-24 bg-white">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center mb-12">
          <h2 class="text-3xl font-bold text-gray-900 mb-4">
            ¿Cómo funciona?
          </h2>
          <p class="text-xl text-gray-600">
            Proceso simple y transparente para propietarios e inquilinos
          </p>
        </div>

        <!-- User Type Selector -->
        <div class="flex justify-center mb-12">
          <div class="inline-flex rounded-lg p-1 bg-gray-100">
            <button
              v-for="type in userTypes"
              :key="type.id"
              @click="activeUserType = type.id"
              :class="[
                'px-6 py-2 text-sm font-medium rounded-md transition-all duration-200',
                activeUserType === type.id
                  ? 'bg-white text-blue-600 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              ]"
            >
              {{ type.label }}
            </button>
          </div>
        </div>

        <!-- Steps for Property Owners -->
        <div v-show="activeUserType === 'owner'" class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div v-for="(step, index) in ownerSteps" :key="index" class="flex flex-col items-center text-center p-6">
            <div class="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 font-bold mb-4">
              {{ index + 1 }}
            </div>
            <h3 class="text-xl font-semibold mb-2">{{ step.title }}</h3>
            <p class="text-gray-600">{{ step.description }}</p>
          </div>
        </div>

        <!-- Steps for Applicants -->
        <div v-show="activeUserType === 'applicant'" class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div v-for="(step, index) in applicantSteps" :key="index" class="flex flex-col items-center text-center p-6">
            <div class="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center text-green-600 font-bold mb-4">
              {{ index + 1 }}
            </div>
            <h3 class="text-xl font-semibold mb-2">{{ step.title }}</h3>
            <p class="text-gray-600">{{ step.description }}</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Add this section before the CTA Section -->
    <section id="call-section" class="py-24 bg-white">
      <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center mb-12">
          <h2 class="text-3xl font-bold text-gray-900 mb-4">
            ¿Prefieres que te llamemos?
          </h2>
          <p class="text-xl text-gray-600">
            Déjanos tu número y te contactaremos para resolver todas tus dudas
          </p>
        </div>

        <div class="bg-white rounded-2xl shadow-sm border border-gray-200 p-8">
          <form @submit.prevent="handleCallRequest" class="space-y-6">
            <div>
              <label for="phone" class="block text-sm font-medium text-gray-700 mb-2">
                Número de teléfono
              </label>
              <div class="relative">
                <input
                  id="phone"
                  v-model="phoneNumber"
                  type="tel"
                  required
                  pattern="[0-9]{9}"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Ej: 666777888"
                />
              </div>
            </div>

            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input
                  id="privacy"
                  v-model="acceptedPrivacy"
                  type="checkbox"
                  required
                  class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
              </div>
              <div class="ml-3">
                <label for="privacy" class="text-sm text-gray-600">
                  Acepto la <router-link to="/privacy-policy" class="text-blue-600 hover:text-blue-700">política de privacidad</router-link> y el tratamiento de mis datos
                </label>
              </div>
            </div>

            <button
              type="submit"
              :disabled="isSubmitting"
              class="w-full flex justify-center items-center px-4 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <span v-if="isSubmitting">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Enviando...
              </span>
              <span v-else>Solicitar llamada</span>
            </button>

            <Transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <div v-if="showSuccess" class="mt-4 p-4 bg-green-50 rounded-md">
                <div class="flex">
                  <CheckCircleIcon class="h-5 w-5 text-green-400" />
                  <div class="ml-3">
                    <p class="text-sm font-medium text-green-800">
                      Solicitud recibida. Te llamaremos lo antes posible.
                    </p>
                  </div>
                </div>
              </div>
            </Transition>
          </form>
        </div>
      </div>
    </section>

    <!-- Replace the FAQ section with this updated version -->
    <section class="py-24 bg-gray-50">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center mb-16">
          <h2 class="text-4xl font-bold text-gray-900 mb-4">
            Preguntas frecuentes
          </h2>
          <p class="text-xl text-gray-600">
            Todo lo que necesitas saber sobre nuestra plataforma
          </p>
        </div>

        <div class="grid md:grid-cols-2 gap-8">
          <div v-for="(faq, index) in faqs" :key="index" 
            class="bg-white rounded-2xl p-6 hover:shadow-lg transition-shadow duration-200"
          >
            <button
              class="w-full text-left group"
              @click="toggleFaq(index)"
            >
              <div class="flex justify-between items-start gap-4">
                <h3 class="text-lg font-semibold text-gray-900 group-hover:text-blue-600 transition-colors">
                  {{ faq.question }}
                </h3>
                <ChevronDownIcon
                  class="h-6 w-6 text-gray-400 group-hover:text-blue-600 transition-all duration-200 flex-shrink-0 mt-1"
                  :class="{ 'rotate-180': faq.isOpen }"
                />
              </div>
              <div
                class="mt-4 overflow-hidden transition-all duration-200"
                :class="{ 'h-0': !faq.isOpen, 'h-auto': faq.isOpen }"
              >
                <p class="text-gray-600 leading-relaxed">
                  {{ faq.answer }}
                </p>
              </div>
            </button>
          </div>
        </div>

        <!-- Additional Support Section -->
        <div class="mt-16 text-center">
          <p class="text-gray-600 mb-4">¿No encuentras la respuesta que buscas?</p>
          <router-link
            to="/contact"
            class="inline-flex items-center text-blue-600 hover:text-blue-700 font-medium"
          >
            Contacta con nuestro equipo
            <ArrowRightIcon class="h-5 w-5 ml-2" />
          </router-link>
        </div>
      </div>
    </section>

    

    <!-- CTA Section -->
    <section class="bg-indigo-700 py-16">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 class="text-3xl font-bold text-white mb-6">
          Empieza a gestionar tus propiedades hoy mismo
        </h2>
        <p class="text-xl text-blue-100 mb-8">
          Sin comisiones, sin intermediarios, todo el control en tus manos
        </p>
        <router-link
          to="/signup"
          class="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50 transition-colors"
        >
          Crear cuenta gratis
        </router-link>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { CheckCircleIcon, ChevronDownIcon, ArrowRightIcon } from '@heroicons/vue/24/solid'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '@/firebase'

const activeTab = ref('publicacion')

const featureTabs = [
  { id: 'publicacion', label: 'Publicación' },
  { id: 'contratos', label: 'Contratos' },
  { id: 'gestion', label: 'Gestión' },
  { id: 'ofertas', label: 'Ofertas' }
]

const activeUserType = ref('owner')

const userTypes = [
  { id: 'owner', label: 'Para propietarios' },
  { id: 'applicant', label: 'Para solicitantes' }
]

const ownerSteps = [
  {
    title: 'Crea tu cuenta de propietario',
    description: 'Regístrate gratuitamente y verifica tu identidad para comenzar a publicar propiedades.'
  },
  {
    title: 'Publica tus propiedades',
    description: 'Sube fotos, añade descripciones detalladas y establece tus condiciones de venta o alquiler.'
  },
  {
    title: 'Gestiona ofertas y firma',
    description: 'Recibe ofertas, verifica a los candidatos y firma contratos digitalmente de forma segura.'
  }
]

const applicantSteps = [
  {
    title: 'Crea tu perfil',
    description: 'Regístrate y completa tu perfil con la información necesaria para aplicar a propiedades.'
  },
  {
    title: 'Encuentra tu propiedad ideal',
    description: 'Explora el catálogo de propiedades y utiliza filtros para encontrar la que mejor se ajuste a ti.'
  },
  {
    title: 'Aplica y firma',
    description: 'Envía tu oferta, verifica tu información y firma el contrato digital cuando seas seleccionado.'
  }
]

const faqs = ref([
  {
    question: "¿Qué documentos necesito para empezar?",
    answer: "Solo necesitas tu documento de identidad, según el tipo de perfil que tengas, te guiaremos paso a paso en el proceso con el resto de documentos que necesites.",
    isOpen: false
  },
  {
    question: "¿Es segura la firma digital?",
    answer: "Sí, utilizamos un sistema de firma digital certificado que cumple con toda la normativa legal vigente y garantiza la máxima seguridad.",
    isOpen: false
  },
  {
    question: "¿Qué garantías tengo al usar la plataforma?",
    answer: "Ofrecemos verificación de identidad, contratos legales, sistema seguro de pagos y soporte especializado durante todo el proceso.",
    isOpen: false
  },
  {
    question: "¿Cuánto cuesta el servicio?",
    answer: "El registro es gratuito. Solo aplicamos una pequeña cantidad fija al completar una operación exitosa. Sin costes ocultos, sin suscripciones, sin comisiones.",
    isOpen: false
  },
  {
    question: "¿Cómo verifican a los inquilinos y propietarios?",
    answer: "Realizamos verificaciones automáticas de identidad y documentos para garantizar la seguridad.",
    isOpen: false
  },
  {
    question: "¿Qué soporte ofrecen durante el contrato?",
    answer: "Contamos con un equipo especializado disponible para resolver cualquier incidencia contemplada en nuestro contrato.",
    isOpen: false
  }
])

const phoneNumber = ref('')
const acceptedPrivacy = ref(false)
const isSubmitting = ref(false)
const showSuccess = ref(false)

async function handleCallRequest() {
  if (!phoneNumber.value || !acceptedPrivacy.value) return
  
  isSubmitting.value = true
  
  try {
    await addDoc(collection(db, 'callRequests'), {
      phoneNumber: phoneNumber.value,
      createdAt: new Date(),
      status: 'pending'
    })
    
    phoneNumber.value = ''
    acceptedPrivacy.value = false
    showSuccess.value = true
    
    // Hide success message after 5 seconds
    setTimeout(() => {
      showSuccess.value = false
    }, 5000)
  } catch (error) {
    console.error('Error submitting call request:', error)
  } finally {
    isSubmitting.value = false
  }
}

function toggleFaq(index) {
  faqs.value = faqs.value.map((faq, i) => ({
    ...faq,
    isOpen: i === index ? !faq.isOpen : false
  }))
}

function scrollToCallSection() {
  const element = document.getElementById('call-section')
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}
</script>

<style scoped>
.animate-float {
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.group:hover h3 {
  color: rgb(37, 99, 235);
}

/* Smooth height animation for FAQ answers */
.overflow-hidden {
  transition: height 0.3s ease-in-out;
}
</style>

<script>
export default {
  name: 'LandingPage'
}
</script> 