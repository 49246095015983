import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/global.css'  // This should include Tailwind CSS
import './assets/tailwind.css'

const app = createApp(App)

app.config.globalProperties.$encodeSpecialChars = (str) => {
  if (typeof str !== 'string') return str;
  return str.replace(/[^\u0020-\u007E]/g, char => `&#${char.charCodeAt(0)};`);
}

app.mixin({
  mounted() {
    const metaCharset = document.querySelector('meta[charset]');
    if (metaCharset) {
      metaCharset.setAttribute('charset', 'utf-8');
    } else {
      const newMetaCharset = document.createElement('meta');
      newMetaCharset.setAttribute('charset', 'utf-8');
      document.head.appendChild(newMetaCharset);
    }
  }
})

app.use(router).mount('#app')