<template>
  <transition name="slide">
    <div v-if="isVisible" class="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 flex flex-col sm:flex-row justify-between items-center gap-4 shadow-lg z-[9999]">
      <div class="space-y-2">
        <p class="text-gray-300 text-sm">
          Utilizamos cookies propias y de terceros para seguimiento anonimizado de visitas y muestra de publicidad relevante. Al hacer click en Aceptar todo, usted da su consentimiento a nuestro uso de las cookies.
        </p>
        <router-link to="/privacy-policy" class="text-sm text-blue-400 hover:text-blue-300 underline">
          Política de privacidad
        </router-link>
      </div>
      <div class="flex gap-3">
        <button 
          @click="reject" 
          class="px-4 py-2 text-sm rounded border border-gray-600 hover:bg-gray-700 transition-colors"
        >
          Rechazar
        </button>
        <button 
          @click="accept" 
          class="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 text-sm rounded transition-colors"
        >
          Aceptar
        </button>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const isVisible = ref(false)
const emit = defineEmits(['accept', 'reject'])

function accept() {
  localStorage.setItem('cookiesAccepted', 'true')
  isVisible.value = false
  emit('accept')
}

function reject() {
  localStorage.setItem('cookiesAccepted', 'false')
  isVisible.value = false
  emit('reject')
}

onMounted(() => {
  const cookiesAccepted = localStorage.getItem('cookiesAccepted')
  if (cookiesAccepted === null) {
    isVisible.value = true
  }
})
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-in-out;
  position: relative;
  z-index: 9999;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(100%);
}
</style>