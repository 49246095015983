<template>
  <div class="min-h-screen bg-custom-bg flex flex-col">
    <!-- Navigation wrapper - Remove container constraints from nav -->
    <div class="bg-white shadow-sm w-full">
      <nav class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex-shrink-0 flex items-center">
              <!-- You can add your logo here -->
              <router-link to="/" class="text-xl font-bold text-indigo-600">notarial</router-link>
            </div>
            <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
              <router-link 
                to="/contracts" 
                :class="[
                  $route.path.startsWith('/contracts') 
                    ? 'border-indigo-500 text-gray-900' 
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                ]"
              >
                Contratos
              </router-link>
              <router-link 
                to="/profile" 
                :class="[
                  $route.path === '/profile' 
                    ? 'border-indigo-500 text-gray-900' 
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                ]"
              >
                Perfil
              </router-link>
            </div>
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:items-center">
            <!-- Dynamic login/logout button -->
            <button 
              v-if="!isAuthenticated" 
              @click="navigateToLogin" 
              class="bg-indigo-700 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-indigo-500 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Identificarse
            </button>
            <div v-else class="relative dropdown-container">
              <button 
                @click="toggleUserMenu" 
                class="flex items-center space-x-2 text-gray-700 hover:text-gray-900 focus:outline-none"
              >
                <span class="text-sm font-medium">{{ user?.displayName || user?.email }}</span>
                <svg 
                  class="h-5 w-5" 
                  :class="{ 'transform rotate-180': isUserMenuOpen }"
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 20 20" 
                  fill="currentColor"
                >
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
              
              <!-- Dropdown menu -->
              <div 
                v-if="isUserMenuOpen" 
                class="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
              >
                <div class="py-1">
                  <router-link 
                    to="/profile" 
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    @click="isUserMenuOpen = false"
                  >
                    Modificar perfil
                  </router-link>
                </div>
                <div class="py-1">
                  <button 
                    @click="logout" 
                    class="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                  >
                    Salir
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="-mr-2 flex items-center sm:hidden">
            <!-- Mobile menu button -->
            <button @click="toggleMobileMenu" type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-controls="mobile-menu" aria-expanded="false">
              <span class="sr-only">Open main menu</span>
              <!-- Icon when menu is closed -->
              <svg :class="{'hidden': mobileMenuOpen, 'block': !mobileMenuOpen }" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <!-- Icon when menu is open -->
              <svg :class="{'block': mobileMenuOpen, 'hidden': !mobileMenuOpen }" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </nav>

      <!-- Mobile menu -->
      <div 
        v-show="mobileMenuOpen" 
        class="sm:hidden"
        id="mobile-menu"
      >
        <div class="pt-2 pb-3 space-y-1">
          <router-link
            to="/contracts"
            :class="[
              $route.path.startsWith('/contracts')
                ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700',
              'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
            ]"
            @click="mobileMenuOpen = false"
          >
            Contratos
          </router-link>
          <router-link
            to="/profile"
            :class="[
              $route.path === '/profile'
                ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700',
              'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
            ]"
            @click="mobileMenuOpen = false"
          >
            Perfil
          </router-link>
        </div>
        <div class="pt-4 pb-3 border-t border-gray-200">
          <div v-if="isAuthenticated" class="space-y-1">
            <button
              @click="logout"
              class="block w-full text-left px-4 py-2 text-base font-medium text-red-600 hover:bg-gray-100"
            >
              Salir
            </button>
          </div>
          <div v-else class="px-4 py-2">
            <button
              @click="navigateToLogin"
              class="w-full bg-indigo-700 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-indigo-500 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Identificarse
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Main content - Keep container for content only -->
    <div :class="[
      mainContentClass,
      'flex-grow' // Add this to ensure proper spacing
    ]">
      <router-view></router-view>
    </div>

    <!-- Footer wrapper - Similar to nav wrapper -->
    <div class="bg-gray-800 w-full">
      <footer class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-white py-4">
        <div class="flex justify-center items-center space-x-4 text-sm">
          <p>© 2024 notarial. Todos los derechos reservados.</p>
          <span class="text-gray-500">|</span>
          <router-link to="/privacy-policy" class="hover:text-gray-300 transition-colors">
            Política de privacidad
          </router-link>
        </div>
      </footer>
    </div>
    <CookiesBanner 
      @accept="handleCookiesAccept" 
      @reject="handleCookiesReject" 
    />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import CookiesBanner from './components/CookiesBanner.vue'

export default {
  name: 'App',
  components: {
    CookiesBanner
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const isAuthenticated = ref(false)
    const mobileMenuOpen = ref(false)
    const isUserMenuOpen = ref(false)
    const user = ref(null)

    const mainContentClass = computed(() => {
      const isViewContractPublic = route.name === 'ViewContractPublic'
      return {
        'container mx-auto px-4 py-8': true,
        'lg:max-w-4xl xl:max-w-5xl': isViewContractPublic
      }
    })

    const toggleMobileMenu = () => {
      mobileMenuOpen.value = !mobileMenuOpen.value
    }

    const navigateToLogin = () => {
      router.push('/login')
      mobileMenuOpen.value = false
    }

    const logout = async () => {
      const auth = getAuth()
      try {
        await signOut(auth)
        router.push('/')
        mobileMenuOpen.value = false
      } catch (error) {
        console.error('Error signing out:', error)
      }
    }

    const handleCookiesAccept = () => {
      // You can implement additional cookie acceptance logic here
      console.log('Cookies accepted')
    }

    const handleCookiesReject = () => {
      // You can implement additional cookie rejection logic here
      console.log('Cookies rejected')
    }

    const toggleUserMenu = () => {
      isUserMenuOpen.value = !isUserMenuOpen.value
    }

    // Close user menu when clicking outside
    onMounted(() => {
      document.addEventListener('click', (event) => {
        const userMenu = document.querySelector('.relative')
        if (userMenu && !userMenu.contains(event.target)) {
          isUserMenuOpen.value = false
        }
      })

      const auth = getAuth()
      onAuthStateChanged(auth, (currentUser) => {
        isAuthenticated.value = !!currentUser
        user.value = currentUser
      })
    })

    return {
      mainContentClass,
      isAuthenticated,
      mobileMenuOpen,
      isUserMenuOpen,
      user,
      toggleUserMenu,
      toggleMobileMenu,
      navigateToLogin,
      logout,
      handleCookiesAccept,
      handleCookiesReject
    }
  }
}
</script>

<style>
/* Update z-index hierarchy */
.bg-white.shadow-sm {
  position: relative;
  z-index: 50;
}

nav {
  position: relative;
}

.dropdown-container {
  position: relative;
  isolation: isolate;
}

.dropdown-container .absolute {
  z-index: 60;
}

#mobile-menu {
  position: relative;
  z-index: 50;
}
</style>