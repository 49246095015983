import { createRouter, createWebHistory } from 'vue-router'
import { auth } from './firebase'
import { onAuthStateChanged } from 'firebase/auth'
import LandingPage from './components/LandingPage.vue'

const routes = [
  { 
    path: '/', 
    name: 'Landing',
    component: LandingPage,
    meta: { 
      title: 'Inicio',
      requiresAuth: false 
    }
  },
  { 
    path: '/contracts/:id', 
    component: () => import('./components/ViewContractPublic.vue'),
    meta: { requiresAuth: true, allowInvited: true } 
  },
  { 
    path: '/dashboard', 
    component: () => import('./views/Dashboard.vue'),
    meta: { requiresAuth: true },
    children: [
      { path: '', redirect: '/contracts' },
      { 
        path: 'profile', 
        component: () => import('./components/Profile.vue')
      },
    ]
  },
  {
    path: '/contracts',
    component: () => import('./components/Contracts.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contracts/add',
    component: () => import('./components/AddContract.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contracts/edit/:id',
    name: 'EditContract',
    component: () => import('./components/EditContract.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/contracts/:id',
    name: 'ViewContractPublic',
    component: () => import('./components/ViewContractPublic.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  { 
    path: '/signup', 
    component: () => import('./components/SignUp.vue')
  },
  { 
    path: '/login', 
    component: () => import('./components/Login.vue')
  },
  { 
    path: '/profile', 
    component: () => import('./components/Profile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('./views/PrivacyPolicy.vue'),
    meta: {
      title: 'Política de Privacidad'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

let isAuthReady = false
let initialAuthCheck = new Promise((resolve) => {
  onAuthStateChanged(auth, (user) => {
    isAuthReady = true
    resolve(user)
  })
})

router.beforeEach(async (to, from, next) => {
  if (!isAuthReady) {
    await initialAuthCheck
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const allowInvited = to.matched.some(record => record.meta.allowInvited);
  const isAuthenticated = auth.currentUser;
  
  if (requiresAuth && !isAuthenticated) {
    if (allowInvited && to.name === 'ViewContractPublic') {
      // For invited users, allow access to ViewContractPublic but store the path for post-login redirect
      next({ path: '/login', query: { redirect: to.fullPath } });
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router