import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com`,
    // Optional configs if you need them:
    // messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    // appId: process.env.VUE_APP_FIREBASE_APP_ID,
    // measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };

